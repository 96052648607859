var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-center"},[_c('table',{staticClass:"table-auto w-4/5 mt-20 md:mt-0"},[_vm._m(0),_c('tbody',_vm._l((_vm.listaLocales),function(local,k){return _c('tr',{key:k},[_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[_c('router-link',{staticClass:"hover:text-blue-500 hover:underline",attrs:{"to":{
              name: 'InventarioProveedor',
              params: {
                id: local.local_id,
                user: local.user_id,
                nombre: local.nombre
              },
            }}},[_vm._v(_vm._s(_vm.firstLetterUpperCase(local.nombre)))])],1),_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[_vm._v(" "+_vm._s(_vm.formato(local.precio_usd))+" ")]),_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[(local.show === '1')?_c('span',[_c('p',{staticClass:"text-green-700"},[_vm._v("Disponible")])]):_c('span',[_c('p',{staticClass:"text-red-700"},[_vm._v("No Disponible")])])]),_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[(local.existencia === '1')?_c('span',[_c('p',{staticClass:"text-green-700"},[_vm._v("Disponible")])]):_c('span',[_c('p',{staticClass:"text-red-700"},[_vm._v("Agotado")])])])])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Nombre local")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Precio USD")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Disponibilidad Local")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Existencia Producto")])])])}]

export { render, staticRenderFns }