<template>
  <div class="flex justify-center">
    <table class="table-auto w-9/12 mt-20 md:mt-0">
      <thead>
        <tr>
          <th class="border-2 px-4 py-2">Local</th>
          <th class="border-2 px-4 py-2">Ubicación</th>
        </tr>
      </thead>
      <tbody v-for="(local, k) in resultadoLocales" :key="k">
        <tr>
          <td class="border-2 px-4 py-2 text-center">
            <router-link
              class="hover:text-blue-500 hover:underline"
              :to="{
                name: 'ProveedorCuenta',
                params: {
                  id: local.id,
                  user: local.user_id,
                  nombre: firstLetterUpperCase(local.nombre),
                },
              }"
              >{{ firstLetterUpperCase(local.nombre) }}</router-link
            >
          </td>
          <td class="border-2 px-4 py-2 text-center">
            {{ firstLetterUpperCase(local.ubicacion) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    resultadoLocales: Array
  },
  methods: {
    firstLetterUpperCase(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
  },
};
</script>

<style></style>
