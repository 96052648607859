<template>
	<div class="flex justify-center">
		<table class="table-auto w-4/5 mt-20 md:mt-0">
			<thead>
				<tr>
					<th class="border-2 px-4 py-2"># Orden de Compra</th>
					<th class="border-2 px-4 py-2">Estatus</th>
					<th class="border-2 px-4 py-2">Fecha Entrega</th>
					<th class="border-2 px-4 py-2">Monto USD</th>
					<th class="border-2 px-4 py-2">Cobrado</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(compra, k) in clientePedidoChild" :key="k">
					<td class="border-2 px-4 py-2 text-center">
						<router-link
							class="hover:text-blue-500 hover:underline"
							:to="{
								name: 'PedidoID',
								params: {
									id: compra.id,
									nombre: nombre,
									telefono: telefono,
									estatus: compra.estatus,
									cobrado: compra.cobrado
								}
							}"
						>
							<div>{{ compra.id.padStart(4, "0") }}</div></router-link
						>
					</td>
					<td class="border-2 px-4 py-2 text-center">
						{{ firstLetterUpperCase(compra.estatus) }}
					</td>
					<td class="border-2 px-4 py-2 text-center">
						{{ formatoFecha(compra.fecha_entrega) }}
					</td>
					<td class="border-2 px-4 py-2 text-center">
						{{ formato(compra.total_factura) }}
					</td>
					<td class="border-2 px-4 py-2 text-center">
						<span v-if="compra.cobrado === '1'">
							<p class="text-green-700">Cobrado</p>
						</span>
						<span v-else>
							<p class="text-red-700">No Cobrado</p>
						</span>
					</td>
				</tr>
				<tr>
					<td colspan="5" class="w-full py-3">
						<div class="flex justify-between">
							<div class="pl-10 py-4">
								<button
									class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-orange-300"
									v-if="dataInicio > 0"
									@click="cargarDataAnterior()"
								>
									Anterior
								</button>
							</div>
							<div class="pr-10 py-4">
								<button
									class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-orange-300"
									v-if="continuaDataChild"
									@click="cargarDataSiguiente()"
								>
									Siguiente
								</button>
							</div>
						</div>
						<span v-if="showMessage" class="flex justify-center mt-2 mb-5">{{
							message
						}}</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { formato, firstLetterUpperCase, formatoFecha } from "@/functions.js";
export default {
	data() {
		return {
			dataInicio: 0,
			dataFin: 20,
			paginacionNumero: 20,
			clientePedidoChild: [],
			continuaDataChild: false,
			showMessage: false,
			message: ""
		};
	},
	props: {
		clientePedido: Array,
		nombre: String,
		telefono: String,
		id: String,
		continuaData: Boolean
	},
	mounted() {
		this.clientePedidoChild = this.clientePedido;
		this.continuaDataChild = this.continuaData;
	},
	methods: {
		formato,
		firstLetterUpperCase,
		formatoFecha,
		cargarDataAnterior() {
			this.dataInicio -= this.paginacionNumero;
			this.dataFin -= this.paginacionNumero;
			this.busquedaNueva();
		},
		cargarDataSiguiente() {
			this.dataInicio += this.paginacionNumero;
			this.dataFin += this.paginacionNumero;
			this.busquedaNueva();
		},
		async busquedaNueva() {
			this.showMessage = true;
			this.message = "Buscando...";
			await this.$apollo
				.query({
					query: require("@/graphql/queries/searchEngine.gql"),
					variables: {
						text: "hola",
						dataInicio: 0,
						dataFin: 0,
						dataInicioCompra: this.dataInicio,
						dataFinCompra: this.dataFin,
						nuevaBusqueda: false,
						idBusqueda: this.id,
						tipoBusqueda: ""
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.continuaDataChild =
						data.data.searchEngine.usuario_compras[0].continuaData;
					this.clientePedidoChild =
						data.data.searchEngine.usuario_compras[0].compras;
					this.message = "Completado";
				})
				.catch(error => {
					console.log(error);
					this.message = "Error de comunicación, por favor volver a intentar.";
				});
		}
	}
};
</script>

<style></style>
