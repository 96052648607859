<template>
  <div class="flex justify-center">
    <table class="table-auto w-9/12 mt-20 md:mt-0">
      <thead>
        <tr>
          <th class="border-2 px-4 py-2">Nombre</th>
          <th class="border-2 px-4 py-2">Disponibilidad</th>
        </tr>
      </thead>
      <tbody v-for="(vendedor, k) in resultadoVendedores" :key="k">
        <tr>
          <td class="border-2 px-4 py-2 text-center">
            <router-link
              class="hover:text-blue-500 hover:underline"
              :to="{
                name: 'Vendedor',
                params: {
                  id: vendedor.vendedor_id,
                  name: vendedor.nombre.replace(/\s/g, '_'),
                },
              }"
              >{{ firstLetterUpperCase(vendedor.nombre) }}</router-link
            >
          </td>
          <td class="border-2 px-4 py-2 text-center">
            <span v-if="vendedor.disponible === '1'">
              <p class="text-green-700">Disponible</p>
            </span>
            <span v-else>
              <p class="text-red-700">No Disponible</p>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    resultadoVendedores: Array
  },
  methods: {
    firstLetterUpperCase(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  }
};
</script>

<style></style>
