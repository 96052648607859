<template>
  <div class="flex justify-center">
    <table class="table-auto w-9/12 mt-20 md:mt-0">
      <thead>
        <tr>
          <th class="border-2 px-4 py-2">Producto</th>
          <th class="border-2 px-4 py-2">Rubro</th>
          <th class="border-2 px-4 py-2">Ver locales</th>
        </tr>
      </thead>
      <tbody v-for="(producto, k) in resultadoProducto" :key="k">
        <tr>
          <td class="border-2 px-4 py-2 text-center">
            <router-link
              class="hover:text-blue-500 hover:underline"
              :to="{
                name: 'EditarProducto',
                params: {
                  id: producto.id.padStart(4, '0'),
                },
              }"
              >{{ firstLetterUpperCase(producto.producto) }}</router-link
            >
          </td>
          <td class="border-2 px-4 py-2 text-center">
            {{ firstLetterUpperCase(producto.rubro) }}
          </td>
          <td class="border-2 px-4 py-2 text-center">
            <input
              v-if="producto.locales.length > 0"
              class="bg-gray-200 shadow-md rounded w-16"
              type="button"
              value="Ver"
              @click="mostrarLista(k)"
            />
            <p v-else>Sin proveedor asociado</p>
          </td>
        </tr>
        <tr v-if="mostrarDetalle[k]">
          <td colspan="6" class="w-full py-3">
            <lista-locales :listaLocales="producto.locales"></lista-locales>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ListaLocales from './ListaLocales.vue';
import { firstLetterUpperCase } from "@/functions.js";
export default {
  components: {
    ListaLocales
  },
  data() {
    return {
      mostrarDetalle: []
    }
  },
  props: {
    resultadoProducto: Array
  },
  methods: {
    firstLetterUpperCase,
    mostrarLista(index) {
      this.$set(this.mostrarDetalle, index, !this.mostrarDetalle[index]);
    }
  }
};
</script>

<style></style>
