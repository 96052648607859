<template>
	<div class="flex justify-center">
		<table class="table-auto w-9/12 mt-20 md:mt-0">
			<thead>
				<tr>
					<th class="border-2 px-4 py-2">Nombre</th>
					<th class="border-2 px-4 py-2">Teléfono</th>
					<th class="border-2 px-4 py-2">Dirección</th>
					<th class="border-2 px-4 py-2">Pedidos</th>
					<th class="border-2 px-4 py-2">Editar</th>
				</tr>
			</thead>
			<tbody v-for="(cliente, k) in resultadoClientes" :key="k">
				<tr>
					<td class="border-2 px-4 py-2 text-center">
						<router-link
							class="hover:text-blue-500 hover:underline"
							:to="{
								name: 'DetalleCliente',
								params: {
									id: parseInt(cliente.id),
									name: cliente.name,
									phone: cliente.telefono
								}
							}"
							>{{ firstLetterUpperCase(cliente.name) }}</router-link
						>
					</td>
					<td class="border-2 px-4 py-2 text-center">
						{{ cliente.telefono }}
					</td>
					<td class="border-2 px-4 py-2 text-center">
						{{ firstLetterUpperCase(cliente.address) }}
					</td>
					<td class="border-2 px-4 py-2 text-center">
						<input
							v-if="cliente.compras.length > 0"
							class="bg-gray-200 shadow-md rounded w-16"
							type="button"
							value="Ver"
							@click="mostrarLista(k)"
						/>
						<p v-else>Sin pedidos realizados</p>
					</td>
					<td class="border-2 px-4 py-2 text-center">
						<button
							v-if="cliente.compras.length >= 0"
							class="bg-gray-200 shadow-md rounded w-16"
						>
							<router-link
								:to="{
									name: 'EditarCliente',
									params: {
										id: parseInt(cliente.id)
									}
								}"
								>Editar</router-link
							>
						</button>
					</td>
				</tr>
				<tr v-if="mostrarDetalle[k]">
					<td colspan="6" class="w-full py-3">
						<clientes-pedidos
							:clientePedido="cliente.compras"
							:nombre="cliente.name"
							:telefono="cliente.telefono"
							:id="cliente.id"
							:continuaData="cliente.continuaData"
						></clientes-pedidos>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import ClientesPedidos from "./ClientesPedidos.vue";
import { firstLetterUpperCase } from "@/functions.js";
export default {
	components: {
		ClientesPedidos
	},
	data() {
		return {
			mostrarDetalle: []
		};
	},
	props: {
		resultadoClientes: Array
	},
	methods: {
		firstLetterUpperCase,
		mostrarLista(index) {
			this.$set(this.mostrarDetalle, index, !this.mostrarDetalle[index]);
		}
	}
};
</script>

<style></style>
