var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-center"},[_c('table',{staticClass:"table-auto w-9/12 mt-20 md:mt-0"},[_vm._m(0),_vm._l((_vm.resultadoClientes),function(cliente,k){return _c('tbody',{key:k},[_c('tr',[_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[_c('router-link',{staticClass:"hover:text-blue-500 hover:underline",attrs:{"to":{
							name: 'DetalleCliente',
							params: {
								id: parseInt(cliente.id),
								name: cliente.name,
								phone: cliente.telefono
							}
						}}},[_vm._v(_vm._s(_vm.firstLetterUpperCase(cliente.name)))])],1),_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[_vm._v(" "+_vm._s(cliente.telefono)+" ")]),_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[_vm._v(" "+_vm._s(_vm.firstLetterUpperCase(cliente.address))+" ")]),_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[(cliente.compras.length > 0)?_c('input',{staticClass:"bg-gray-200 shadow-md rounded w-16",attrs:{"type":"button","value":"Ver"},on:{"click":function($event){return _vm.mostrarLista(k)}}}):_c('p',[_vm._v("Sin pedidos realizados")])]),_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[(cliente.compras.length >= 0)?_c('button',{staticClass:"bg-gray-200 shadow-md rounded w-16"},[_c('router-link',{attrs:{"to":{
								name: 'EditarCliente',
								params: {
									id: parseInt(cliente.id)
								}
							}}},[_vm._v("Editar")])],1):_vm._e()])]),(_vm.mostrarDetalle[k])?_c('tr',[_c('td',{staticClass:"w-full py-3",attrs:{"colspan":"6"}},[_c('clientes-pedidos',{attrs:{"clientePedido":cliente.compras,"nombre":cliente.name,"telefono":cliente.telefono,"id":cliente.id,"continuaData":cliente.continuaData}})],1)]):_vm._e()])})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Nombre")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Teléfono")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Dirección")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Pedidos")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Editar")])])])}]

export { render, staticRenderFns }