<template>
  <div class="flex justify-center">
    <table class="table-auto w-9/12 mt-20 md:mt-0">
      <thead>
        <tr>
          <th class="border-2 px-4 py-2"># Orden de Compra</th>
          <th class="border-2 px-4 py-2">Estatus</th>
          <th class="border-2 px-4 py-2">Fecha Entrega</th>
        </tr>
      </thead>
      <tbody v-for="(compra, k) in resultadoOrdenCompra" :key="k">
        <tr>
          <td class="border-2 px-4 py-2 text-center">
            <router-link
              class="hover:text-blue-500 hover:underline"
              :to="{
                name: 'PedidoID',
                params: {
                  id: compra.id,
                  nombre: compra.name,
                  telefono: compra.numero_telefono_movil,
                  estatus: compra.estatus,
                  cobrado: compra.cobrado
                }
              }"
            >
              <div>{{ compra.id.padStart(4, "0") }}</div></router-link
            >
          </td>
          <td class="border-2 px-4 py-2 text-center">
            {{ firstLetterUpperCase(compra.estatus) }}
          </td>
          <td class="border-2 px-4 py-2 text-center">
            {{ formatoFecha(compra.fecha_entrega) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { firstLetterUpperCase, formatoFecha } from "@/functions.js";
export default {
  props: {
    resultadoOrdenCompra: Array
  },
  methods: {
    firstLetterUpperCase,
    formatoFecha
  }
}
</script>

<style>

</style>