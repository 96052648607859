<template>
  <div class="flex justify-center">
    <table class="table-auto w-4/5 mt-20 md:mt-0">
      <thead>
        <tr>
          <th class="border-2 px-4 py-2">Nombre local</th>
          <th class="border-2 px-4 py-2">Precio USD</th>
          <th class="border-2 px-4 py-2">Disponibilidad Local</th>
          <th class="border-2 px-4 py-2">Existencia Producto</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(local, k) in listaLocales" :key="k">
          <td class="border-2 px-4 py-2 text-center">
            <router-link
              class="hover:text-blue-500 hover:underline"
              :to="{
                name: 'InventarioProveedor',
                params: {
                  id: local.local_id,
                  user: local.user_id,
                  nombre: local.nombre
                },
              }"
              >{{ firstLetterUpperCase(local.nombre) }}</router-link
            >
          </td>
          <td class="border-2 px-4 py-2 text-center">
            {{ formato(local.precio_usd) }}
          </td>
          <td class="border-2 px-4 py-2 text-center">
            <span v-if="local.show === '1'">
              <p class="text-green-700">Disponible</p>
            </span>
            <span v-else>
              <p class="text-red-700">No Disponible</p>
            </span>
          </td>
          <td class="border-2 px-4 py-2 text-center">
            <span v-if="local.existencia === '1'">
              <p class="text-green-700">Disponible</p>
            </span>
            <span v-else>
              <p class="text-red-700">Agotado</p>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    listaLocales: Array
  },
  methods: {
    firstLetterUpperCase(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    formato(args) {
      return new Intl.NumberFormat("de-DE").format(parseFloat(args).toFixed(2));
    }
  }
}
</script>

<style>
</style>