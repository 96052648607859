<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark w-full text-white fixed md:static py-1"
		>
			<span class="ml-2 font-bold">Busqueda</span>
			<div class="mr-2">
				<span class="font-bold">Categoría: </span>
				<select
					id="select"
					class="py-1 h-8 w-48 rounded-lg bg-verdedark border border-white text-white px-4 focus:outline-none"
					v-model="filtroSelect"
					@click="paginacion()"
				>
					<option value="productos">
						{{ "Productos (" + String(resultadoProducto.length) + ")" }}
					</option>
					<option value="locales">
						{{ "Locales (" + String(resultadoLocales.length) + ")" }}
					</option>
					<option value="ordenCompra">
						{{ "Orden Compra (" + String(resultadoOrdenCompra.length) + ")" }}
					</option>
					<option value="clientes">
						{{ "Clientes (" + String(resultadoClientes.length) + ")" }}
					</option>
					<option value="vendedores">
						{{ "Vendedores (" + String(resultadoVendedores.length) + ")" }}
					</option>
				</select>
			</div>
		</div>

		<span v-if="showMessage" class="flex justify-center my-5">{{
			message
		}}</span>

		<!-- llamamos los componentes de busqueda filtrado -->
		<div v-if="!noResultado">
			<div v-if="filtroSelect === 'productos'">
				<productos :resultadoProducto="resultadoProducto"></productos>
			</div>
			<div v-if="filtroSelect === 'locales'">
				<locales :resultadoLocales="resultadoLocales"></locales>
			</div>
			<div v-if="filtroSelect === 'ordenCompra'">
				<orden-compra
					:resultadoOrdenCompra="resultadoOrdenCompra"
				></orden-compra>
			</div>
			<div v-if="filtroSelect === 'clientes'">
				<clientes :resultadoClientes="resultadoClientes"></clientes>
			</div>
			<div v-if="filtroSelect === 'vendedores'">
				<vendedores :resultadoVendedores="resultadoVendedores"></vendedores>
			</div>
			<div class="flex justify-between">
				<div class="pl-32 py-4">
					<button
						class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
						v-if="dataInicio > 0"
						@click="cargarDataAnterior()"
					>
						Anterior
					</button>
				</div>
				<div class="pr-40 py-4">
					<button
						class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
						v-if="continuaData"
						@click="cargarDataSiguiente()"
					>
						Siguiente
					</button>
				</div>
			</div>
		</div>
		<div v-else class="flex justify-center mt-12">
			<h1 class="text-2xl">No hay coincidencias en la base de datos</h1>
		</div>
	</div>
</template>

<script>
import productos from "@/components/ResultadoProducto";
import locales from "@/components/ResultadoLocales";
import vendedores from "@/components/ResultadoVendedores";
import clientes from "@/components/ResultadoClientes";
import OrdenCompra from "@/components/ResultadoOrdenCompra";

export default {
	components: {
		productos,
		locales,
		vendedores,
		clientes,
		OrdenCompra
	},
	data() {
		return {
			filtroSelect: "productos",
			dataInicio: 0,
			dataFin: 20,
			dataInicioCompra: 0,
			dataFinCompra: 20,
			nuevaBusqueda: true,
			idBusqueda: "",
			tipoBusqueda: "",
			resultadoProducto: [],
			resultadoLocales: [],
			resultadoOrdenCompra: [],
			resultadoVendedores: [],
			resultadoClientes: [],
			continuaData: false,
			continuaDataArray: [],
			paginacionNumero: 20,
			noResultado: false,
			showMessage: false,
			message: "",
			primeraBusqueda: true
		};
	},
	async mounted() {
		this.$store.state.isLoading = true;
		await this.busquedaNueva();
		this.$store.state.isLoading = false;
	},
	methods: {
		seleccionarCategoria() {
			//ordenar de mayor a menor dejando como ultimo ordenes de compra
			if (this.resultadoProducto.length > 0) {
				this.filtroSelect = "productos";
			} else {
				if (this.resultadoLocales.length > 0) {
					this.filtroSelect = "locales";
				} else {
					if (this.resultadoClientes.length > 0) {
						this.filtroSelect = "clientes";
					} else {
						if (this.resultadoVendedores.length > 0) {
							this.filtroSelect = "vendedores";
						} else {
							if (this.resultadoOrdenCompra.length > 0) {
								this.filtroSelect = "ordenCompra";
							} else {
								this.noResultado = true;
							}
						}
					}
				}
			}
		},
		cargarDataAnterior() {
			this.dataInicio -= this.paginacionNumero;
			this.dataFin -= this.paginacionNumero;
			this.tipoBusqueda = this.filtroSelect;
			this.busquedaNueva();
		},
		cargarDataSiguiente() {
			this.dataInicio += this.paginacionNumero;
			this.dataFin += this.paginacionNumero;
			this.tipoBusqueda = this.filtroSelect;
			this.busquedaNueva();
		},
		paginacion() {
			if (this.filtroSelect === "productos") {
				this.continuaData = this.continuaDataArray[0].producto;
			}
			if (this.filtroSelect === "locales") {
				this.continuaData = this.continuaDataArray[0].locales;
			}
			if (this.filtroSelect === "ordenCompra") {
				this.continuaData = this.continuaDataArray[0].orden_compra;
			}
			if (this.filtroSelect === "clientes") {
				this.continuaData = this.continuaDataArray[0].clientes;
			}
			if (this.filtroSelect === "vendedores") {
				this.continuaData = this.continuaDataArray[0].vendedor;
			}
		},
		async busquedaNueva() {
			this.showMessage = true;
			this.message = "Buscando...";
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/searchEngine.gql"),
					variables: {
						text: this.$route.params.text,
						dataInicio: this.dataInicio,
						dataFin: this.dataFin,
						dataInicioCompra: this.dataInicioCompra,
						dataFinCompra: this.dataFinCompra,
						nuevaBusqueda: this.nuevaBusqueda,
						idBusqueda: this.idBusqueda,
						tipoBusqueda: this.tipoBusqueda
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					if (this.tipoBusqueda === "") {
						this.continuaDataArray = data.data.searchEngine.continuaData;
						this.resultadoProducto = data.data.searchEngine.productos;
						this.resultadoLocales = data.data.searchEngine.locales;
						this.resultadoOrdenCompra = data.data.searchEngine.orden_compra;
						this.resultadoVendedores = data.data.searchEngine.vendedor;
						this.resultadoClientes = data.data.searchEngine.usuario_compras;
					} else {
						if (this.tipoBusqueda === "productos") {
							this.resultadoProducto = data.data.searchEngine.productos;
							this.continuaDataArray[0].producto =
								data.data.searchEngine.continuaData[0].producto;
						}
						if (this.tipoBusqueda === "locales") {
							this.resultadoLocales = data.data.searchEngine.locales;
							this.continuaDataArray[0].locales =
								data.data.searchEngine.continuaData[0].locales;
						}
						if (this.tipoBusqueda === "ordenCompra") {
							this.resultadoOrdenCompra = data.data.searchEngine.orden_compra;
							this.continuaDataArray[0].orden_compra =
								data.data.searchEngine.continuaData[0].orden_compra;
						}
						if (this.tipoBusqueda === "vendedores") {
							this.resultadoVendedores = data.data.searchEngine.vendedores;
							this.continuaDataArray[0].vendedor =
								data.data.searchEngine.continuaData[0].vendedor;
						}
						if (this.tipoBusqueda === "clientes") {
							this.resultadoClientes = data.data.searchEngine.usuario_compras;
							this.continuaDataArray[0].clientes =
								data.data.searchEngine.continuaData[0].clientes;
						}
					}
					this.message = "Completado";
					this.paginacion();
					if (this.primeraBusqueda) {
						this.seleccionarCategoria();
						this.primeraBusqueda = false;
					}
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
					this.message = "Error de comunicación, por favor volver a intentar.";
				});
		}
	}
};
</script>

<style></style>
