var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-center"},[_c('table',{staticClass:"table-auto w-9/12 mt-20 md:mt-0"},[_vm._m(0),_vm._l((_vm.resultadoLocales),function(local,k){return _c('tbody',{key:k},[_c('tr',[_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[_c('router-link',{staticClass:"hover:text-blue-500 hover:underline",attrs:{"to":{
              name: 'ProveedorCuenta',
              params: {
                id: local.id,
                user: local.user_id,
                nombre: _vm.firstLetterUpperCase(local.nombre),
              },
            }}},[_vm._v(_vm._s(_vm.firstLetterUpperCase(local.nombre)))])],1),_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[_vm._v(" "+_vm._s(_vm.firstLetterUpperCase(local.ubicacion))+" ")])])])})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Local")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Ubicación")])])])}]

export { render, staticRenderFns }