var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-center"},[_c('table',{staticClass:"table-auto w-4/5 mt-20 md:mt-0"},[_vm._m(0),_c('tbody',[_vm._l((_vm.clientePedidoChild),function(compra,k){return _c('tr',{key:k},[_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[_c('router-link',{staticClass:"hover:text-blue-500 hover:underline",attrs:{"to":{
							name: 'PedidoID',
							params: {
								id: compra.id,
								nombre: _vm.nombre,
								telefono: _vm.telefono,
								estatus: compra.estatus,
								cobrado: compra.cobrado
							}
						}}},[_c('div',[_vm._v(_vm._s(compra.id.padStart(4, "0")))])])],1),_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[_vm._v(" "+_vm._s(_vm.firstLetterUpperCase(compra.estatus))+" ")]),_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[_vm._v(" "+_vm._s(_vm.formatoFecha(compra.fecha_entrega))+" ")]),_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[_vm._v(" "+_vm._s(_vm.formato(compra.total_factura))+" ")]),_c('td',{staticClass:"border-2 px-4 py-2 text-center"},[(compra.cobrado === '1')?_c('span',[_c('p',{staticClass:"text-green-700"},[_vm._v("Cobrado")])]):_c('span',[_c('p',{staticClass:"text-red-700"},[_vm._v("No Cobrado")])])])])}),_c('tr',[_c('td',{staticClass:"w-full py-3",attrs:{"colspan":"5"}},[_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"pl-10 py-4"},[(_vm.dataInicio > 0)?_c('button',{staticClass:"bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-orange-300",on:{"click":function($event){return _vm.cargarDataAnterior()}}},[_vm._v(" Anterior ")]):_vm._e()]),_c('div',{staticClass:"pr-10 py-4"},[(_vm.continuaDataChild)?_c('button',{staticClass:"bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-orange-300",on:{"click":function($event){return _vm.cargarDataSiguiente()}}},[_vm._v(" Siguiente ")]):_vm._e()])]),(_vm.showMessage)?_c('span',{staticClass:"flex justify-center mt-2 mb-5"},[_vm._v(_vm._s(_vm.message))]):_vm._e()])])],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("# Orden de Compra")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Estatus")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Fecha Entrega")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Monto USD")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Cobrado")])])])}]

export { render, staticRenderFns }